*:focus {
  outline: none;
}

#root {
  width: 100%;
}

/* Header */

#header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #1e2127;
  padding: 0 15px 0 8px;
}

#header-logo {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  align-items: center;
}

#header #logo {
  width: 150px;
  margin: 10px 0;
}

#header-logo > h1 {
  color: #fef8d6;
  font-size: 0.75em;
  margin: 0;
}

#header .img-button {
  width: 150px;
  margin: 0;
}

#header > button {
  background-color: #1e2127;
  border: none;
}

.header-icon {
  color: #fede16;
}

.header-menu {
  background-color: #1e2127;
  margin: 0;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-menu button {
  width: 100%;
  background: #1e2127;
  border: none;
  color: white;
  display: flex;
  font-size: 1.3em;
}

.header-menu button:nth-child(2) {
  margin: 10px 0;
}

.header-menu span {
  width: 80px;
}

.header-men-enter {
  transition: height 300ms ease;
  -webkit-transition: height 0.3s ease;
}
.header-men-active {
  transition: height 300ms ease;
  -webkit-transition: height 0.3s ease;
}
.header-men-exit {
  opacity: 0;
  height: 0px;
  transition: height 300ms ease;
  -webkit-transition: height 0.3s ease;
}
.header-men-exit-active {
  height: 0px;
  transition: height 300ms ease;
  -webkit-transition: height 0.3s ease;
}

/* Content */

#content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3em;
}

.infinitescroll {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 1em;
}

.infinitescroll::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 0;
  height: 0;
}

#send-top {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 99;
  font-size: 18px;
  outline: none;
  cursor: pointer;
  background: #fede16;
  border: 2px solid #282c34;
  border-radius: 10px;
  padding: 10px;
  width: 100px;
  font-size: 1.8em;
  letter-spacing: 1px;
  color: #643c11;
}

#send-top:hover {
  background: #dec630;
}

#logo {
  margin-top: 1em;
  width: 100%;
  max-width: 470px;
  cursor: pointer;
}

#content > h1 {
  color: #fef8d6;
  letter-spacing: 1px;
  margin: 0.5em 0 0.2em;
  text-align: center;
  font-size: 1.4em;
}

#content > h6 {
  color: tomato;
  margin: 0 0 0.5em;
}

#to-random {
  width: 70%;
  margin: 0.45em 0em 0em;
  border-radius: 12px;
  border: 2px solid #282c34;
  max-width: 250px;
}

.img-button:hover {
  opacity: 0.8;
  cursor: pointer;
}

#content > h3 {
  color: #fcfcfc;
  margin: 1em 0;
  font-size: 0.74em;
  text-align: center;
}

#search {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  padding: 0 0.5em;
  max-width: 470px;
}

#search-input {
  margin: 1em;
  background: white;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: 2px solid #cccccc;
  display: grid;
  grid-template-columns: 90% 10%;
  justify-items: center;
  align-items: center;
}

#search-input > input {
  width: 100%;
  font-size: 1.17em;
  border: none;
  height: 28px;
}

#search-input > input::placeholder {
  font-size: 0.95em;
  color: lightgray;
}

#clearSearch {
  cursor: pointer;
}

#filters-applied {
  text-align: center;
  color: white;
  margin: 0 0 1em;
  font-size: 0.9em;
  padding: 0 10px;
}

#filters-applied > span {
  color: #fede16;
}

#filters-applied-total {
  text-align: center;
  color: white;
  margin: 0 0 1em;
  padding: 5px;
}

#filters-applied-total > span {
  color: #fede16;
}

#loading {
  display: flex;
  background: none;
  justify-content: center;
  border: none;
  width: 100%;
}

#empty {
  color: white;
}

/* Landing Page */

#glossary-content {
  display: flex;
  flex-direction: column;
  color: #fede16;
  align-items: center;
  font-size: 1.2em;
  letter-spacing: 0.8px;
}

.glossary-title {
  font-size: 1.3em;
  margin: 0 0;
}

#glossary-top > h2:first-child {
  margin-right: 5px;
}

#glossary-detail {
  color: white;
  font-size: 0.75em;
  margin: 0.5em 0 0;
  text-align: center;
}

#glossary-icons {
  display: flex;
  justify-content: center;
  margin-bottom: 0.5em;
  flex-wrap: wrap;
}

.glossary-img {
  height: 33px;
  margin: 8px;
  border: 2px solid #282c34;
  border-radius: 50%;
}

.glossary-icon {
  color: #ecbf08;
  margin: 8px;
  border: 2px solid #282c34;
  border-radius: 50%;
}

.glossary-selected {
  border: 2px solid #fede16;
}

.landing-label {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 0.5em 0 0.8em;
}

.landing-title {
  margin: 0;
  color: #fede16;
  font-size: 1.4em;
  letter-spacing: 0.9px;
}

.criterion-label {
  display: block;
  max-width: 75%;
  margin: 0 0 10px;
}

.criterion-extra {
  color: white;
  font-size: 1.1em;
  margin: 0 0 0.1em;
}

.landing-toList {
  margin: 0 0 0.1em 0.5rem;
  color: white;
  font-size: 1.1em;
  cursor: pointer;
}

.landing-toList:hover {
  color: #fede16;
}

.toList-icon {
  color: #fede16;
}

.landing-container {
  display: flex;
  flex-direction: column;
  width: 98%;
  margin: 0.5em 0em;
  overflow: auto;
}

.landing-container:last-of-type {
  margin-bottom: 2em;
}

.landing-container > img {
  width: 95%;
  align-self: center;
  margin: 1em 0 0.5em;
}

.landing-weekly {
  align-self: center;
  margin: 0.5em 0 1em;
  color: white;
}

.landing-container > hr {
  width: 90%;
  border-color: #cccccc;
  margin: 1em 0 2em;
  align-self: center;
}

.landing-list {
  margin: 0 1%;
  padding: 0 1%;
  display: flex;
  overflow: auto;
}

.landing-list::after {
  content: "t";
  color: #282c34;
}

.landing-list .movie {
  min-width: 110px;
}

.landing-list::-webkit-scrollbar {
  margin-left: 1em;
  width: 0.5em;
  height: 0.5em;
}

.landing-list::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
}

.landing-list::-webkit-scrollbar-thumb:hover {
  background: #fede16;
}

#footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

#landing-credit {
  order: 2;
  color: white;
  width: 330px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 2em;
  justify-items: center;
  align-items: center;
}

#landing-credit > h4 {
  grid-column: 1/4;
  text-align: center;
}

#landing-credit img {
  width: 140px;
}

#landing-omdb {
  border-radius: 8px;
}

#footer-podcast {
  margin: 1em 0 0;
  width: 90%;
  max-width: 350px;
}

.pod-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px;
}

#footer-podcast a > img {
  max-width: 100%;
  border-radius: 10px;
}

#footer-podcast > img {
  grid-column: 1/3;
  max-width: 100%;
  border-radius: 10px;
}

/* Filter Panel */

#filter-show {
  background: #f4f6f7;
  border: 2px solid #cccccc;
  padding: 8px;
  border-radius: 12px;
  font-size: 1em;
  width: 35%;
  cursor: pointer;
}

#filter-show:hover {
  background: #e5e7e8;
}

#filter-panel {
  width: 85%;
  border-radius: 10px;
  padding: 10px;
  background: #f4f6f7;
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
}

#filter-buttons {
  display: flex;
  justify-content: space-evenly;
}

#filter-apply,
#filter-reset {
  margin-bottom: 10px;
  background: #f4f6f7;
  border: 2px solid #282c34;
  padding: 10px;
  border-radius: 12px;
  font-size: 0.9em;
  box-shadow: 0 0 1px 0px black;
  width: 40%;
  cursor: pointer;
}

#filter-apply:hover,
#filter-reset:hover {
  background: #e5e7e8;
  box-shadow: 0 0 3px 0px black;
}

.filter-select {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2px;
}

.filter-select > label {
  font-size: 1em;
  padding-left: 5px;
  margin-bottom: 0.2em;
}

.sort {
  width: 100%;
  margin-bottom: 0.2em;
}

.bold {
  font-weight: 600;
}

.filter-flex {
  display: flex;
  align-items: center;
}

.filter-flex > img {
  width: 26px;
  margin-left: 4px;
}

.placeholder-flex > img {
  opacity: 0.5;
}

.css-1rhbuit-multiValue img {
  width: 20px;
}

.sort > .css-26l3qy-menu {
  z-index: 3;
}

/* Review Page */

#reviewPage {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reviewPage-loading {
  margin: 10em;
  width: 50%;
}

#review-navbar {
  display: flex;
  justify-content: center;
  width: 100vw;
}

#navbar-content {
  display: flex;
  width: 100vw;
  justify-content: space-between;
  margin-top: 0.5em;
  padding: 5px 10px;
}

#nav-back,
#nav-home {
  height: 34px;
  margin: 0em 0.5em;
}

#reviewPage-title {
  color: white;
  text-align: center;
  font-size: 1.6em;
  margin: 0.5em 0;
  padding: 0 8px;
}

#review-rank {
  color: #fede16;
  text-align: center;
  margin: 0 0 0.8em 0;
  font-size: 1.75em;
  letter-spacing: 1px;
}

#review-poster {
  box-shadow: 0 0 11px 3px black;
  width: 300px;
  max-width: 300px;
  margin-top: 0.5em;
}

#review-card {
  width: 100%;
  color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#review-total {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  margin: 0 0 1em;
  max-width: 480px;
}

.score-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.5em 0;
  color: #fede16;
}

.score-holiday {
  /* color: #D83B4A; */
  color: #9ce38b;
}

.score-holiday span {
  color: tomato;
  /* color: #9CE38B; */
}

.score-row > h1 {
  margin: 0.3em 0 0.3em;
}

.score-row > h1:last-of-type {
  margin-bottom: 0;
}

.score-img,
.score-icon {
  height: 70px;
}

#review-userInteractions-text {
  margin: 0.5em 0 0.5em;
  font-size: 0.8em;
}

#review-userInteractions {
  margin: 0 0 1em;
  width: 100vw;
  display: flex;
  justify-content: center;
}

#review-userInteractions button {
  background: rgb(128, 128, 128, 0.6);
  border: none;
  border-radius: 8px;
  color: #f4f6f7;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.7em;
  margin: 0 12px;
  padding: 5px 10px;
}

#review-userInteractions .active,
#review-userInteractions .active > svg {
  color: #6cea4a;
}

#review-userInteractions svg {
  margin-right: 10px;
  color: white;
}

#review-plot {
  margin: 0 0 0.5em;
  font-size: 1em;
  align-self: flex-start;
  padding: 0 1em;
}

.review-detail {
  color: #fede16;
  font-size: 1.4em;
  letter-spacing: 1px;
  align-self: flex-start;
  margin: 0.5em 1em 0.5em 1.5em;
}

.review-people {
  font-size: 1.2em;
  color: white;
  align-self: flex-start;
  margin: 0em 1em 0.8em 1em;
}

#review-director {
  text-decoration: underline;
  cursor: pointer;
}

#review-awards {
  width: 95%;
}

#review-awards > ul:first-of-type {
  margin-bottom: 1em;
}

#review-awards > .review-people {
  margin-bottom: 0;
}

.awards-list {
  margin: 0.5em 0 0.7em;
}

.awards-list > li {
  margin: 0.25em 0;
}

#review-streaming {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
}

#streaming-title {
  color: #fede16;
  font-size: 1.5em;
  letter-spacing: 1px;
  text-align: center;
  margin: 0px;
}

#review-streaming > div {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 350px;
}

#review-streaming > p {
  color: white;
  margin: 0 0 0.5em 0;
  font-size: 1em;
}

#review-streaming > h2 {
  margin-top: 0em;
  font-size: 1.17em;
  text-align: center;
}

#review-streaming > hr {
  border-color: white;
  width: 50%;
  margin-bottom: 10px;
}

.provider {
  width: 60px;
  margin: 5px;
}

#review-trailer-wrapper {
  margin-top: 10px;
  width: 90%;
  height: 270px;
}

.react-player {
  box-shadow: 0 0 12px 3px black;
  max-height: 220px;
}

#review-other-scores {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin-top: 0.5em;
}

#review-other-scores > h3 {
  margin-right: auto;
}

#review-other-scores > span {
  display: flex;
  font-size: 2.4em;
  color: #fede16;
  align-items: center;
  margin: 0;
  width: 255px;
  height: 70px;
}

#review-other-scores a {
  width: 158px;
  display: flex;
  cursor: pointer;
}

#review-other-scores p {
  margin: 0;
}

#review-other-scores img {
  width: 150px;
  height: 70px;
}

#review-info {
  padding: 10px;
  margin: 1em 0;
  width: 90%;
  box-shadow: 0px 2px 8px 3px black;
  border-radius: 10px;
  max-width: 350px;
}

#review-info td {
  padding: 5px;
}

.review-info-button {
  padding: 0;
  text-align: start;
  background: inherit;
  font-size: inherit;
  border: none;
  margin-right: 4px;
}

#review-info,
#review-info td {
  border: 1px solid black;
  border-collapse: collapse;
}

#review-info tr {
  border-radius: 10px 10px 0 0;
}

#review-info tr:nth-child(odd) {
  background-color: #f4f6f7;
}

#review-info tr:nth-child(even) {
  background-color: #e5e7e8;
}

.search-icon {
  text-decoration: underline;
  cursor: pointer;
}

#butter-row {
  border: none;
  display: flex;
  align-items: center;
}

#butter-row > img {
  width: 20px;
  margin-left: 5px;
}

#review-pod {
  margin: 1em 0;
  width: 92vw;
  justify-self: end;
  grid-row: 6/7;
}

#review-pod a > img {
  width: 100%;
  border-radius: 10px;
}

#review-pod > img {
  grid-column: 1/3;
  width: 100%;
  border-radius: 10px;
}

#review-similar > .review-detail {
  margin-top: 0;
  margin-bottom: 0;
}

/* Random Movie Page */

.random > #logo {
  max-width: 320px;
}

.random-select {
  width: 80%;
  margin: 0.5em;
  max-width: 305px;
  font-size: 0.95em;
}

.random-select > label {
  font-size: 1.1em;
  color: #fede16;
  font-weight: 600;
}

.random-label > span {
  color: white;
}

.random-select .css-1wa3eu0-placeholder {
  color: black;
  font-size: 1.1em;
  font-weight: 600;
}

.random > h2 {
  margin: 0.75em 0 0.4em;
  font-size: 1.2em;
  color: white;
  text-align: center;
}

.random > h2 span {
  color: #fede16;
}

#random-error {
  margin: 0.5em;
  color: tomato;
  text-align: center;
}

#random-content #review-streaming {
  grid-column: 1/3;
  color: white;
}

#random-content hr {
  max-width: 375px;
}

/* RANGE SLIDERS */

#random-range {
  margin-top: 1.2em;
  width: 100%;
}

#filter-range {
  margin: 13px 0;
  width: 100%;
  background-color: #fede16;
}

.range {
  -webkit-appearance: none;
  height: 8px;
  outline: none;
  border-radius: 12px;
}

.range::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 54px;
  height: 54px;
  appearance: none;
  background: url("../media/slider.png");
  cursor: pointer;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  border: none;
  border-radius: 50%;
}

.range::-moz-range-thumb {
  width: 54px;
  height: 54px;
  background: url("../media/slider.png");
  cursor: pointer;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  border: none;
  border-radius: 50%;
}

/* DOUBLE INPUT SLIDER */

.double-range {
  margin-top: 10em;
  margin-bottom: 13px;
  width: 100%;
  position: relative;
}

.filter-select > .double-range {
  margin: 13px 0 20px;
}

.double-range input[type="range"] {
  -webkit-appearance: none;
  height: 8px;
  position: absolute;
  width: 100%;
  border-radius: 12px;
  outline: none;
  background: #ffffff;
}

#filter-panel .double-range input[type="range"] {
  background: #fede16;
  margin: 0;
}

.double-range input[type="range"]:focus::-webkit-slider-runnable-track,
.double-range input[type="range"]:focus::-ms-fill-lower,
.double-range input[type="range"]:focus::-ms-fill-upper {
  background: #ffffff;
  border-radius: 10px;
}

.double-range input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  border: 0;
}

.double-range input[type="range"]::-webkit-slider-thumb {
  z-index: 1;
  position: relative;
  height: 34px;
  width: 34px;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -13px;
  background: url("../media/left.png");
  cursor: pointer;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  border: none;
}

.double-range input[type="range"]:first-of-type::-webkit-slider-thumb {
  background: url("../media/right.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

/* END DOUBLE */

#randomize {
  margin: 0.75em 0 2em;
  border: 2px solid #282c34;
  padding: 6px;
  border-radius: 8px;
  font-size: 0.9em;
  box-shadow: 0 0 1px 0px black;
  width: 38%;
  cursor: pointer;
  font-size: 1.9em;
  letter-spacing: 1px;
  max-width: 150px;
}

.random-loading {
  margin: 1em;
}

#random-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  grid-gap: 10px;
  margin: 0 1em 3em;
}

#random-info {
  color: white;
  grid-column: 1/3;
  text-align: center;
  width: 96vw;
  font-size: 0.7em;
  margin: 1em 0 0;
}

#random-title {
  color: white;
  text-align: center;
  margin: 0 0 0.2em;
  grid-column: 1/3;
  padding: 0 6px;
  font-size: 1.4em;
}

#random-poster {
  box-shadow: 0 0 11px 3px black;
  max-width: 180px;
  cursor: pointer;
  grid-column: 1/3;
  margin-bottom: 0.4em;
}

#random-choice {
  display: flex;
  justify-content: center;
  margin-bottom: 2em;
  width: 400px;
}

#random-total {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column: 1/3;
  width: 100%;
  max-width: 480px;
  font-size: 0.9em;
}

#random-btn-row {
  margin-top: 1em;
  grid-column: 1/3;
}

.random-nav {
  margin: 0.2em 0.2em;
  border: 2px solid #282c34;
  padding: 6px 10px;
  border-radius: 12px;
  box-shadow: 0 0 1px 0px black;
  cursor: pointer;
  font-size: 1.6em;
  font-weight: 500;
  letter-spacing: 1px;
}

.random-btn:hover {
  background: #ecbf08;
  box-shadow: 0 0 3px 0px black;
}

.random-nav:first-of-type {
  justify-self: end;
}

.random-nav:last-of-type {
  justify-self: start;
}

#random-total > .score-row,
#random-total > .score-row > h1:first-of-type {
  margin-top: 0;
}

.random-btn {
  background: #fede16;
  color: #643c11;
}

/* Registration */

.user-access-loader {
  margin-top: 25%;
  align-self: center;
}

.user-access {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-form {
  justify-self: center;
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
}

.user-access > h4 {
  text-align: center;
  color: white;
}

.user-access > a > svg {
  margin-left: 5px;
}

.user-access > h3 {
  color: #fede16;
}

.user-access > p {
  color: tomato;
  margin: 0px;
  margin-bottom: 10px;
  font-size: 0.8em;
  text-align: center;
  max-width: 350px;
}

.userForm-label {
  font-size: 0.75em;
  color: #cccccc;
  display: flex;
  flex-direction: column;
  width: 270px;
  cursor: text;
}

.userForm-label > input {
  margin: 0.2em 0 1em;
  border: none;
  border-bottom: 1px solid #cccccc;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  font-size: 1.2em;
  background: #282c34;
  border-radius: 0;
  color: white;
}

.userForm-label:focus-within {
  color: #fede16;
}

.userForm-label:focus-within > input {
  border-color: #fede16;
  outline: none;
}

.user-form p {
  margin: -0.8em 0.1em 2em;
  font-size: 0.9em;
  color: #cccccc;
  align-self: flex-start;
}

.register-icon {
  color: #fede16;
  margin-right: 2px;
}

.userForm-button {
  border-radius: 6px;
  padding: 0.3em;
  font-size: 1.6em;
  width: 165px;
  border: none;
  background: #fede16;
  color: #643c11;
  cursor: pointer;
}

#login-form button {
  border-radius: 6px;
  padding: 0.3em;
  font-size: 1.7em;
  width: 110px;
}

.user-form > button:hover {
  background: #ecbf08;
}

.user-form > h5 {
  align-self: center;
  font-size: 0.8em;
  color: white;
}

.user-access a {
  color: #fede16;
  text-decoration: none;
  cursor: pointer;
}

.user-access a:hover {
  text-decoration: underline;
}

/* Profile */

.user-profile {
  max-width: 900px; /* Maybe good? */
  display: flex;
  flex-direction: column;
  padding: 1em 2em;
  color: white;
}

.user-profile > h1 {
  align-self: center;
}

/* User Lists */

#userLists {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em 0;
}

.userLists-tab {
  display: flex;
}

.userLists-tab button {
  background: #282c34;
  color: #fede16;
  border: 1px solid #fede16;
  border-radius: 6px;
  margin: 0 4px;
  font-size: 1.2em;
  padding: 6px;
  text-align: center;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.userLists-tab .active {
  color: #282c34;
  background: #fede16;
}

/* Game */

#game {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em 0;
}

.gameForm {
  justify-self: center;
  width: 80%;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  align-items: center;
}

.gameForm-playerLabel {
  display: flex;
  align-items: center;
  width: 100%;
}

.gameForm-label {
  font-size: 0.75em;
  color: #cccccc;
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: text;
}

.gameForm-label > input {
  margin: 0.2em 0 1em;
  border: none;
  border-bottom: 1px solid #cccccc;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  font-size: 1.2em;
  background: #282c34;
  border-radius: 0;
  color: white;
  width: 92%;
}

.gameForm-label:focus-within {
  color: #fede16;
}

.gameForm-label:focus-within > input {
  border-color: #fede16;
  outline: none;
}

.gameForm-removePlayer {
  color: #fede16;
  cursor: pointer;
}

.gameForm-btnGroup {
  margin-top: 10%;
  grid-column: 1/2;
  display: flex;
  justify-content: center;
  width: 100%;
}

.gameForm-btn {
  margin-top: 4%;
  cursor: pointer;
  font-size: 1.75rem;
  padding: 0.5%;
  width: 100%;
  margin: 1% 2%;
  border: transparent;
}

.gameForm-btn:last-of-type {
  background: #fede16;
  color: #643c11;
}

/* Width Specific */

@media screen and (max-width: 370px) {
  #content > h1 {
    font-size: 1.3em;
  }

  #content > h3 {
    font-size: 0.65em;
  }

  #search-input > input::placeholder {
    font-size: 0.81em;
  }

  #to-random {
    width: 100%;
  }

  #random-info {
    font-size: 0.6em;
  }

  #random-title {
    font-size: 1.5em;
  }

  #random-total {
    font-size: 1.3em;
  }

  .glossary-title {
    font-size: 1.17em;
  }

  #glossary-detail {
    font-size: 0.65em;
  }

  .glossary-img {
    height: 25px;
  }

  .landing-title {
    font-size: 1.15em;
    letter-spacing: 1px;
  }

  .landing-toList {
    margin: 0 0;
    font-size: 0.95em;
  }

  #landing-credit {
    width: 300px;
  }

  #landing-credit img {
    width: 110px;
  }

  .css-1wa3eu0-placeholder {
    font-size: 0.85rem;
  }

  .tooltip {
    width: 230px;
  }

  #review-navbar {
    padding: 6px 0px;
  }

  #nav-back,
  #nav-home {
    height: 30px;
  }

  .review-people {
    margin: 0em 0.5em 0.8em;
  }

  #review-streaming > div {
    width: 280px;
  }

  #review-info {
    font-size: 0.9em;
    width: 92vw;
  }

  #review-poster,
  #random-poster {
    width: 85%;
  }

  .random-nav {
    padding: 6px;
    font-size: 1.5em;
    font-weight: 500;
    letter-spacing: 1px;
    margin-bottom: 0.5em;
  }

  #random-total h1 {
    font-size: 1.4em;
  }

  #random-content #review-streaming {
    font-size: 0.9em;
  }

  .butter {
    height: 20px;
  }

  .movie-total {
    font-size: 0.75em;
  }

  .movie-rank {
    font-size: 0.72em;
  }

  .movie-title {
    font-size: 0.78em;
  }
}

@media screen and (min-width: 410px) {
  #content > h3 {
    font-size: 0.78em;
  }

  #search-input > input::placeholder {
    font-size: 1em;
  }

  #filters-applied {
    font-size: 0.95em;
  }

  #random-content {
    grid-gap: 5px;
  }

  #random-poster {
    max-width: 220px;
  }

  .random-nav {
    margin: 0.4em 0.2em;
  }

  #random-title {
    padding: 4px;
  }

  .landing-title {
    font-size: 1.45em;
    letter-spacing: 0.6px;
  }

  .landing-toList {
    font-size: 1.1em;
  }

  .landing-list .movie {
    /* Gonna need to adjust this on different screen sizes */
    min-width: 120px;
  }

  #landing-credit {
    width: 350px;
  }

  .movie {
    width: 120px;
    margin: 0.9em 0.4em;
  }

  .movie-poster {
    height: 170px;
  }

  .butter {
    height: 24px;
  }

  .movie-total {
    font-size: 0.82em;
  }

  .movie-rank {
    font-size: 0.78em;
  }

  .movie-title {
    font-size: 0.82rem;
  }

  #nav-back,
  #nav-home {
    height: 36px;
  }

  #reviewPage-title {
    padding: 0 5px;
  }
}

@media screen and (min-width: 500px) {
  #content > h3 {
    font-size: 1em;
    margin-top: 1.2em;
  }

  #review-trailer-wrapper {
    width: 80%;
    height: 350px;
    align-self: center;
  }

  .react-player {
    max-height: 300px;
  }

  #review-info {
    width: 85%;
    max-width: 430px;
  }

  #review-pod {
    margin: 1.5em 0 0;
    max-width: 250px;
    justify-self: end;
    grid-row: 6/7;
  }

  #filters-applied {
    font-size: 0.95em;
  }
}

@media screen and (min-width: 630px) {
  .landing-container > img {
    width: 80%;
    max-width: 575px;
  }

  #content > h3 {
    margin-bottom: 0;
  }

  .glossary-title {
    margin: 0;
  }

  #glossary-detail {
    font-size: 0.8em;
    margin: 0.7em 0 0.3em;
  }

  .glossary-img {
    height: 55px;
  }

  .landing-label {
    margin: 0em 2em 0em;
  }

  .landing-title {
    font-size: 1.5em;
    letter-spacing: 1px;
  }

  .criterion-label {
    max-width: 300px;
    margin: 0;
  }

  .criterion-extra {
    margin: 0 0 2px 0.5em;
  }

  .landing-toList {
    font-size: 1.05em;
    margin: 0 0.5em 0.2em;
  }

  .landing-list .movie {
    /* Gonna need to adjust this on different screen sizes */
    min-width: 130px;
    margin: 1em 0.5em;
  }

  .movie {
    width: 130px;
    padding: 0;
    margin: 0.9em;
    background: #f4f6f7;
    border-radius: 0 0 6px 6px;
    box-shadow: 0px 2px 8px 3px black;
  }

  .movie-link {
    text-decoration: none;
    color: black;
  }

  .movie-poster {
    width: 100%;
    height: 180px;
  }

  .movie-rank {
    font-size: 0.8em;
  }

  .infinite-scroll-component__outerdiv {
    max-width: 900px;
  }

  #search {
    display: grid;
    grid-template-columns: 30% 70%;
    justify-items: center;
    align-items: center;
    width: 100%;
  }

  #search-input {
    width: 90%;
  }

  #search-input > input {
    font-size: 1em;
  }

  #search-input > input::placeholder {
    font-size: 1em;
  }

  #filter-panel {
    width: 80%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    max-width: 915px;
  }

  #filter-show {
    width: 80%;
  }

  .filter-select {
    width: 100%;
  }

  #filter-buttons {
    grid-column: 1/3;
    justify-content: center;
  }

  #filter-apply,
  #filter-reset {
    width: 40%;
    margin: 0 10px;
    max-width: 115px;
  }

  .sort {
    margin-bottom: 0em;
  }

  #review-rank {
    font-size: 1.85em;
  }

  #navbar-content {
    max-width: 650px;
    margin-bottom: 1.5em;
  }

  #nav-back,
  #nav-home {
    height: 38px;
  }

  #review-plot {
    padding: 0 8px;
  }

  .random > #logo {
    margin-top: 0;
  }

  .random #navbar-content {
    margin-bottom: 0.8em;
  }

  #random-title {
    font-size: 1.85em;
  }

  #random-info {
    font-size: 0.9em;
  }

  #random-poster {
    grid-column: 1/2;
    max-width: 270px;
    justify-self: end;
    margin: 0.9em 1.2em 0 0;
  }

  #random-total {
    grid-column: 2/3;
    max-width: 320px;
    justify-self: start;
    display: flex;
    flex-direction: column;
  }

  #random-total > .score-row {
    flex-direction: row;
    align-items: center;
    margin: 1.2em;
  }

  #random-total > .score-row > h1 {
    margin: 0 0.5em;
  }
}

@media screen and (min-width: 700px) {
  #footer {
    flex-direction: row-reverse;
    justify-content: space-between;
    max-width: 740px;
  }

  #reviewPage {
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin: 1em 2em;
    justify-content: center;
    align-items: start;
  }

  .reviewPage-loading {
    grid-column: 1/3;
    justify-self: center;
  }

  #review-navbar {
    width: 100%;
    grid-column: 1/3;
  }

  #reviewPage-title {
    grid-column: 1/3;
    font-size: 2.55em;
    text-align: center;
    margin-top: 0em;
    color: white;
    padding: 15px;
  }

  #review-rank {
    grid-column: 1/3;
    grid-row: 3/4;
    font-size: 1.85em;
  }

  #review-poster {
    grid-column: 1/2;
    margin: 0;
    max-width: 250px;
    align-self: end;
    justify-self: end;
  }

  #review-card {
    grid-column: 2/3;
    grid-row: 3/9;
    width: 95%;
    max-width: 900px;
    align-items: start;
    justify-self: start;
  }

  #review-total {
    margin: 0.35em 0.85em;
    padding: 0;
    justify-items: center;
  }

  #review-plot {
    font-size: 0.95em;
    margin: 0 0 0 1.6em;
  }

  #review-card > h3 {
    align-self: flex-start;
    margin: 1em 1.5em 0.5em;
    order: 1;
  }

  #review-card > p {
    order: 1;
  }

  .review-people {
    font-size: 1.1em;
    margin: 0em 1.7em;
  }

  #review-awards {
    order: 1;
  }

  #review-awards > ul {
    padding-inline-start: 60px;
  }

  #review-streaming {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 0 1em;
  }

  #review-streaming > div {
    width: 90%;
  }

  #review-streaming > p {
    margin: 0 0 10px 0;
    font-size: 1.1em;
  }

  #review-streaming > h3 {
    font-size: 1.3em;
    margin: 0.5em 0 0;
  }

  #review-streaming > h2 {
    margin-top: 0.5em;
  }

  #review-streaming > hr {
    width: 40%;
    margin: 0.5em;
  }

  #review-trailer-wrapper {
    order: 1;
    width: 90%;
    height: 350px;
    align-self: center;
  }

  .react-player {
    max-height: 250px;
  }

  #review-info {
    max-width: 250px;
    width: 100%;
    grid-row: 4/6;
    grid-column: 1/2;
    margin: 1em 0 0 0;
    padding: 0px;
    align-self: start;
    justify-self: end;
    font-size: 0.9em;
  }

  #review-other-scores {
    max-width: 250px;
    justify-self: end;
    font-size: 0.9em;
  }

  #review-other-scores > .review-detail {
    font-size: 2em;
    align-self: center;
    margin: 10px 0;
  }

  #review-similar {
    margin-top: 2em;
    justify-self: center;
    grid-column: 1/3;
    max-width: 1050px;
  }
}

@media screen and (min-width: 800px) {
  .landing-toList {
    font-size: 1.15em;
  }

  .tooltip {
    width: auto;
    white-space: nowrap;
    margin-left: -50px;
  }

  #review-poster {
    max-width: 300px;
  }

  #review-total {
    max-width: 100%;
  }

  #review-total > .score-img,
  #review-total > .score-icon {
    height: 60px;
    max-height: 60px;
  }

  .provider {
    width: 70px;
    margin: 5px;
  }

  #review-streaming > div {
    max-width: 95%;
  }

  #review-pod {
    max-width: 300px;
  }

  #review-info {
    max-width: 300px;
    width: 100%;
  }

  #review-other-scores {
    max-width: 300px;
    justify-self: end;
    font-size: 0.9em;
  }
}

@media screen and (min-width: 1000px) {
  .infinite-scroll-component__outerdiv {
    max-width: 1100px;
  }

  .landing-container {
    max-width: 1000px;
  }

  #review-trailer-wrapper {
    margin-top: 10px;
    width: 80%;
    height: 400px;
    align-self: center;
  }

  .react-player {
    max-height: 330px;
  }

  /* #review-similar > .landing-list {
        justify-content: center;
    } */
}

@media screen and (min-width: 1100px) {
  #reviewPage-title {
    font-size: 2.75em;
  }

  #review-total > .score-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0.5em 0;
    color: #fede16;
    width: 90%;
  }

  #review-total > .score-row > h1 {
    margin: 0 0.5em;
    font-size: 1.75em;
  }
}

@media screen and (min-width: 1150px) {
  #review-similar > .landing-list {
    justify-content: center;
  }
}

@media screen and (min-width: 1250px) {
  .score-row > h1 {
    font-size: 1.95em;
  }
}

@media screen and (min-width: 1400px) {
  #send-top {
    right: 10%;
  }

  .landing-container {
    max-width: 1100px;
  }

  #review-trailer-wrapper {
    width: 88%;
    height: 515px;
    align-self: center;
  }

  .react-player {
    max-height: 425px;
  }

  #review-similar {
    margin-top: 1.2em;
  }
}

@media screen and (min-width: 1700px) {
  #send-top {
    right: 16%;
  }
}

/* OUTAGE MSG */

#outage-msg {
  background: tomato;
  padding: 10px;
  max-width: 450px;
  border-radius: 10px;
  margin: 1em;
}

#outage-msg > h3 {
  margin: 0 0 0 0.5em;
}

#outage-msg > hr {
  border-color: black;
}

#outage-msg > p {
  margin: 1em 0.5em;
  font-size: 0.9em;
}
