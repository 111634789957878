.movie {
    width: 110px;
    padding: 0;
    margin: .9em .4em;
    background: #F4F6F7;
    border-radius: 0 0 6px 6px;
    box-shadow: 0px 2px 8px 3px black;
    position: relative;
} 

.infinite-scroll-component__outerdiv {
    width: 100%;
}

.movie-link {
    text-decoration: none;
    color: black;
}

.movie-poster {
    width: 100%;
    height: 150px;
}

.info {
    height: 100px;
    display: flex;
    flex-direction: column;
}

.info-review {
    display: flex;
    justify-content: flex-start;
    align-items:  center;
    margin-bottom: 5px;
    padding: 0px 4px;
}

.movie-total {
    margin: 0;
    font-size: .78em;
    display: flex;
    align-items: center;
}

.rank-type-icon {
    margin: 0;
    font-size: .78em;
    height: 22px;
    margin-right: 4px;
}

.butter {
    height: 22px;
    margin-left: auto;
}

.movie-title {
    display: -webkit-box;
    -webkit-line-clamp: 2; 
    -webkit-box-orient: vertical;
    line-height: 1.5em;
    height: 3em;
    padding: 0em .8em;
    align-self: flex-start;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: .8rem;
}

.movie-accolades {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 5px;
    margin-top: auto;
    margin-bottom: .3em;
}

.movie-accolades > img {
    margin-right: 5px;
    height: 20px;
}

.movie-rank {
    margin: 0;
    font-size: .76em;
    margin-left: auto;
    padding-right: 2px;
}

.movie-icons {
    /* display: none; */
    display: flex;
    position: absolute;
    background: rgb(39, 43, 51, .5);
    width: 25px;
    height: 40px;
    border-radius: 12px;
    flex-direction: column;
    align-items: center;
    padding: 4px;
    top: 2px;
    left: 75px;
}

.movie-icon {
    opacity: .8;
    color: #F4F6F7;
    margin: 2px 0;
    cursor: pointer;
}

/* .movie:hover .movie-icons {
    display: flex;
} */

/* .movie-icon:hover ADD BACK ON NON-MOBILE {
    opacity: 1;
    color: tomato;
} */

.watch-active {
    opacity: 1;
    color: #FEDE16;
}

.seen-active {
    opacity: 1;
    color: tomato;
}

/* .movie-icon:first-child:hover ADD BACK ON NON-MOBILE{
    color: #FEDE16;
} */

/* Movie Lists */

#list-break {
    width: 60%;
}

#list-pagination {
    /* margin: 1em 0 10em;  SET A MAX-WIDTH*/
    width: 98vw;
    max-width: 900px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pagination-buttons {
    margin: 1.2em 0;
}

.pagination-arrows {
    margin-bottom: 5em;
}

#list-pagination button {
    cursor: pointer;
    background: #282C34;
    color: #FEDE16;
    border: 1px solid #FEDE16;
    border-radius: 6px;
}

.pagination-buttons button {
    margin: 0 .5em;
    font-size: 1em;
    width: 50px;
    padding: 6px 0;
    text-align: center;
    line-height: normal;
}

.pagination-arrows button {
    margin: 0 4px;
    font-size: 1.2em;
    padding: 6px;
    text-align: center;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pagination-arrows svg {
    margin-top: 2px;
}

#list-pagination .current, #list-pagination button:hover {
    color: #282C34;
    background: #FEDE16;
}