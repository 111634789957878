/* Font styling here */

body {
  margin: 0;
  font-family: 'Noto Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #282C34;
}

* {
  font-family: 'Noto Sans', sans-serif;
}

.title-font {
  font-family: 'Big Noodle', Arial, serif;
}

@font-face {
  font-family: 'Big Noodle';
  src: local('Big Noodle'), url(./styles/big_noodle_titling.ttf) format('opentype');
}